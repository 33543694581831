import { Box } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

const HeroBanner = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundImage: "url(/img/hero_banner.png)",
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "845px",
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          position: "absolute",
          top: 0,
          left: 0,
          width: "430px",
          height: "300px",
          backgroundColor: "#203864",
          opacity: 0.7,
          typography: "h1",
          color: theme.palette.primary.light,
          display: "flex",
          flexWrap: "wrap",
          alignContent: "center",
        }}
      >
        Tokenizing <br></br>Livestock for Financial Inclusion
      </Box>
      <Box
        sx={{
          zIndex: 1,
          position: "absolute",
          top: "10px",
          right: "10px",
          backgroundImage: "url(/img/logo.png)",
          width: "200px",
          height: "200px",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      ></Box>
    </Box>
  );
};

export default HeroBanner;
