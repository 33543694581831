import React from "react";
import { Box, Grid } from "@mui/material";
import ImageButton from "../Shared/ImageButton";
import { useTheme } from "@mui/material/styles";

const FollowSubSection = () => {
  const theme = useTheme();
  return (
    <div>
      <Grid
        container
        sx={{
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.primary.main,
          flexDirection: "column",
          alignItems: "center",
          mb: "10px",
        }}
      >
        <Grid
          item
          sx={{
            typography: "h3",
            color: theme.palette.primary.main,
            mt: "10px",
          }}
        >
          FOLLOW
        </Grid>
        <Grid
          item
          container
          sx={{
            typography: "body1",
            color: theme.palette.primary.light,
            mt: "10px",
            width: "150px",
            justifyContent: "center",
          }}
          gap={1}
        >
          <ImageButton
            src="/img/facebook.svg"
            height="30px"
            theme={theme}
            onClick={() => {
              document.location =
                "https://www.facebook.com/Infocorptechnologies/";
            }}
          ></ImageButton>
          <ImageButton
            src="/img/twitter.svg"
            height="30px"
            theme={theme}
            onClick={() => {
              document.location = "https://twitter.com/infocorptech";
            }}
          ></ImageButton>
          <ImageButton
            src="/img/linkedin.svg"
            height="30px"
            theme={theme}
            onClick={() => {
              document.location =
                "https://www.linkedin.com/company/infocorptechnologies/";
            }}
          ></ImageButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default FollowSubSection;
