import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";

const AwardsSubSection = () => {
  const theme = useTheme();
  return (
    <Box sx={{ backgroundColor: theme.palette.primary.dark }}>
      <Box
        sx={{
          pt: "20px",
          pb: "20px",
          typography: "h3",
          color: theme.palette.secondary.light,
          textAlign: "center",
        }}
      >
        AWARDS
      </Box>
      <Grid
        container
        sx={{
          justifyContent: "center",
          pb: "20px",
        }}
      >
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            textAlign: "center",
            [theme.breakpoints.up("sm")]: {
              textAlign: "right",
            },
          }}
        >
          <img src="/img/eya_logo.png" width="204px" height="131px" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{
            textAlign: "center",
          }}
        >
          <img src="/img/fish_tail.png" width="95.5938px" height="128.469px" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            textAlign: "center",
            [theme.breakpoints.up("sm")]: {
              textAlign: "left",
            },
          }}
        >
          <img
            src="/img/fintech_award.png"
            width="159.328px"
            height="108.234px"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AwardsSubSection;
