import { createTheme } from "@mui/material/styles";
import { themeTypography, themePalette, themeComponent } from "./theme-light";

export const themes = (customization) => {
  let theme = createTheme({
    palette: themePalette(),
    customization: {
      fontFamily: "'Roboto', sans-serif",
    },
    shape: {
      borderRadius: 2,
    },
  });

  theme = themeComponent(theme);
  theme = themeTypography(theme);
  if (customization) theme = createTheme(theme, customization);

  return theme;
};

export default themes;
