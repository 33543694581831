import React, { useState } from "react";
import { Grid, Container, Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const VideoBackdrop = ({ ...props }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        backgroundImage: "url(/img/banner_img_1.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          backgroundColor: "black",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const YoutubeVideo = () => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <Box
      sx={{
        backgroundImage: "url(/img/video_one_new.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        height: "360px",
        width: "530px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        m: "20px",
      }}
    >
      {showVideo ? (
        <iframe
          width="400px"
          height="320px"
          src="https://www.youtube.com/embed/vA-cYo6CTCQ?autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      ) : (
        <Button
          variant="outlined"
          onClick={() => {
            setShowVideo(true);
          }}
        >
          Youtube
        </Button>
      )}
    </Box>
  );
};

const VideoIntro = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          typography: "h2",
          textAlign: "center",
          color: theme.palette.primary.light,
          width: "100%",
          mt: "20px",
          mb: "20px",
        }}
      >
        REDEFINING FINANCIAL INCLUSION
      </Box>
      <Box
        sx={{
          typography: "body",
          textAlign: "justify",
          width: "100%",
          color: theme.palette.secondary.light,
          pl: "20px",
          pr: "20px",
          pb: "20px",
        }}
      >
        In today’s world, a platform that brings together the financial and
        livestock ecosystem for enabling the financially excluded does not
        exists. Most solutions are designed to solve the problems in silos.
        FinTech are focusing mainly on getting faster, better and cheaper but
        does not necessarily translate to success for financial inclusion.
        AgriTech are focusing mainly on tracking the livestock but not the
        ownership, and on commercial farming solutions for farmers that are
        largely financially included and economically sustainable. With a large
        part of the livestock owning populations of the world being unbanked
        smallholder farmers, a new disruptive approach that cuts across silos to
        redefine financial inclusion is needed.
      </Box>
    </Box>
  );
};

const YoutubeSection = ({ ...props }) => {
  return (
    <VideoBackdrop>
      <Grid container>
        <Grid container item xs={12} lg={4} justifyContent="center">
          <YoutubeVideo />
        </Grid>
        <Grid container item xs={12} lg={8}>
          <VideoIntro />
        </Grid>
      </Grid>
    </VideoBackdrop>
  );
};

export default YoutubeSection;
