import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Navbar from "./layout/NavBar";
import themes from "./themes";
import { HomePage } from "./pages";

const App = () => {
  const theme = themes();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HomePage />
      {/* <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/">
            <Route index element={<HomePage theme={theme}></HomePage>} />
          </Route>
          <Route path="/Home">
            <Route index element={<HomePage theme={theme}></HomePage>} />
          </Route>
        </Routes>
      </BrowserRouter> */}
    </ThemeProvider>
  );
};
export default App;
