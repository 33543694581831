import { createTheme } from "@mui/material";

const themePalette = () => {
  return {
    mode: "light",
    background: {
      default: "#f7ffff",
      minWidth: "390px",
    },
    primary: {
      dark: "#002762",
      main: "#004e90",
      light: "#c4dcff",
    },
    secondary: {
      dark: "#93aacc",
      main: "#c4dcff",
      light: "#f7ffff",
    },
    avatarBackdrop: "#ffffff",
    action: {
      disabledBackground: "#c4dcff",
    },
    logo: {
      backgroundImage: "url('img/logo.png')",
    },
  };
};

const themeComponent = (theme) => {
  return createTheme(theme, {
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: theme.palette.secondary.light,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: theme.palette.secondary.dark,
            backgroundColor: theme.palette.secondary.light,
            "&:hover": {
              backgroundColor: theme.palette.secondary.dark,
              color: theme.palette.secondary.light,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          root: {
            "& .MuiStepLabel-label.Mui-disabled": {
              color: theme.palette.secondary.dark,
            },
            "& .MuiStepLabel-label.Mui-active": {
              color: theme.palette.secondary.light,
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            "& .MuiStepIcon-active": { color: "red" },
            "& .MuiStepIcon-completed": { color: "green" },
            "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" },
          },
        },
      },
    },
  });
};

const themeTypography = (theme) => {
  return createTheme(theme, {
    typography: {
      fontFamily: theme?.customization?.fontFamily,
      h1: {
        color: theme.palette.primary.dark,
        fontWeight: 500,
        fontSize: "3.125rem",
        lineHeight: 1.235,
        letterSpacing: "0.00735em",
        [theme.breakpoints.down("xl")]: {
          fontSize: "2.75rem",
        },
      },
      h2: {
        color: theme.palette.primary.dark,
        fontWeight: 500,
        fontSize: "1.725rem",
        lineHeight: 1.235,
        letterSpacing: "0.1em",
        [theme.breakpoints.up("sm")]: {
          fontSize: "2.125rem",
        },
      },
      h3: {
        color: theme.palette.primary.dark,
        fontWeight: 400,
        fontSize: "1.5rem",
        lineHeight: 1.235,
        letterSpacing: "0.05em",
        [theme.breakpoints.up("sm")]: {
          fontSize: "1.725rem",
        },
      },
      h5: {
        color: theme.palette.primary.dark,
        fontWeight: 400,
        fontSize: "0.8rem",
        lineHeight: 1.6,
        letterSpacing: "0.0075em",
        [theme.breakpoints.up("sm")]: {
          fontSize: "1.25rem",
        },
      },
      h6: {
        color: theme.palette.primary.dark,
        fontWeight: 400,
        fontSize: "0.8rem",
        lineHeight: 1.6,
        letterSpacing: "0.0075em",
        [theme.breakpoints.up("sm")]: {
          fontSize: "1.25rem",
        },
      },
      body: {
        color: theme.palette.primary.dark,
        fontSize: "1.0rem",
      },
      body2: {
        color: theme.palette.primary.main,
        fontSize: "0.775rem",
      },
      body3: {
        color: theme.palette.primary.main,
        fontWeight: 200,
        fontSize: "0.775rem",
        lineHeight: 1.43,
        letterSpacing: "0.0075em",
        [theme.breakpoints.up("sm")]: {
          fontSize: "0.675rem",
        },
      },
      mainMenuItem: {
        color: theme.palette.primary.main,
        fontWeight: 750,
        fontSize: "0.9rem",
        lineHeight: 1.6,
        letterSpacing: "0.007em",
      },
      tagline: {
        color: theme.palette.secondary.dark,
        fontWeight: 450,
        fontSize: "1.75rem",
        lineHeight: 1.235,
        letterSpacing: "0.00735em",
      },
      taglineHighlight: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: "1.75rem",
        lineHeight: 1.235,
        letterSpacing: "0.00735em",
      },
    },
  });
};

export { themePalette, themeComponent, themeTypography };
