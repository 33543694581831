import React, { useState, useEffect, useRef } from "react";
import { Grid, Container, Box, Fade, Stack, Button } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import {
  HeroBanner,
  YoutubeSection,
  AboutUsSection,
  FarmTrekSection,
  FooterSection,
  AwardsSection,
} from "../components";

const NavigationButton = styled(Button)(({ theme, show }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  transition: "opacity 1s ease-in-out, background-color 0.3s ease-in-out",
  opacity: show ? 1 : 0,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

function NavigationButtons({ refs, items, ...props }) {
  const [showButtons, setShowButtons] = useState(false);
  const sectionRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setShowButtons(entry.isIntersecting);
    });

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section ref={sectionRef}>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          width: "150px",
          zIndex: 2,
          position: "absolute",
          top: "400px",
          right: 0,
          [theme.breakpoints.up("sm")]: {
            top: "100px",
          },
        }}
      >
        {items &&
          items.map((item, index) => (
            <Fade in={showButtons} timeout={(index + 1) * 500}>
              <NavigationButton
                variant="contained"
                color="primary"
                show={showButtons}
                onClick={() => scrollToSection(refs[index])}
              >
                {item}
              </NavigationButton>
            </Fade>
          ))}
      </Stack>
    </section>
  );
}

const scrollToSection = (ref) => {
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }
};

const HomePage = ({ src, ...props }) => {
  const homeRef = useRef(null);
  const aboutUsRef = useRef(null);
  const farmtrekRef = useRef(null);
  const awardsRef = useRef(null);
  const contactsRef = useRef(null);
  const refs = [homeRef, aboutUsRef, farmtrekRef, awardsRef, contactsRef];
  const items = ["Home", "ABOUT US", "FARMTREK", "AWARDS", "CONTACT"];

  return (
    <Box>
      <NavigationButtons items={items} refs={refs} />
      <div ref={homeRef}>
        <HeroBanner />
      </div>
      <YoutubeSection />
      <div ref={aboutUsRef}>
        <AboutUsSection />
      </div>
      <div ref={farmtrekRef}>
        <FarmTrekSection />
      </div>
      <div ref={awardsRef}>
        <AwardsSection />
      </div>
      <div ref={contactsRef}>
        <FooterSection />
      </div>
    </Box>
  );
};

export default HomePage;
