import React from "react";
import ContactSubSection from "./ContactSubSection";
import FollowSubSection from "./FollowSubSection";
import CopyrightSubSection from "./CopyrightSubSection";

const FooterSection = ({ theme, ...props }) => {
  return (
    <>
      <ContactSubSection />
      <FollowSubSection />
      <CopyrightSubSection />
    </>
  );
};

export default FooterSection;
