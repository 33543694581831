import React from "react";
import { styled } from "@mui/material/styles";
import { Box, ButtonBase, Typography } from "@mui/material";

const ImageButtonBase = styled(ButtonBase)(({ ...props }) => ({
  position: "relative",
  height: props.height,
  width: props.height,
  minWidth: props.height,
  [props.theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const ImageTemplate = ({ ...props }) => (
  <span {...props}>
    <img src={props.src}></img>
  </span>
);

const ImageSrc = styled(ImageTemplate)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
  }
`;

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: "calc(50% - 16px)",

  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  width: 32,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
  borderRadius: "50%",
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.primary.light,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

export default function ImageButton({ src, height, title, onClick, ...props }) {
  return (
    <ImageButtonBase focusRipple height={height} onClick={onClick}>
      <ImageSrc src={src} />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
        {title && (
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            sx={{
              position: "relative",
              p: 4,
              pt: 2,
              pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
            }}
          >
            {title}
            <ImageMarked className="MuiImageMarked-root" />
          </Typography>
        )}
      </Image>
    </ImageButtonBase>
  );
}
