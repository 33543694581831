import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

const CopyrightSubSection = () => {
  const theme = useTheme();
  return (
    <div>
      <Box
        sx={{
          typography: "body2",
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.secondary.light,
          height: "50px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        @ {new Date().getFullYear()} ALL RIGHTS RESERVED. INFOCORP TECHNOLOGIES
      </Box>
    </div>
  );
};

export default CopyrightSubSection;
