import React from "react";
import { Grid, Box, Container, autocompleteClasses } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

const ResponsiveImage = styled("img")(({ maxsize, minsize, ...props }) => ({
  display: "block",
  maxHeight: maxsize,
  maxWidth: maxsize,
  minHeight: minsize,
  minWidth: minsize,
  objectFit: "cover",
  height: "100%",
  width: "100%",
  marginLeft: "auto",
  marginRight: "auto",
}));

const FarmTrekSection = ({ ...props }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
      }}
    >
      <Container>
        <Box
          sx={{
            typography: "h2",
            color: theme.palette.primary.light,
            width: "100%",
            textAlign: "center",
            pt: "20px",
            pb: "20px",
          }}
        >
          FARMTREK
        </Box>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              minWidth: "300px",
            }}
          >
            <ResponsiveImage
              src="/img/crosspay_bg_img.png"
              maxWidth="100%"
              minWidth="300px"
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box
              sx={{
                ml: "auto",
                mr: "auto",
                p: "20px",
                width: "100%",
                typography: "body",
                color: theme.palette.primary.light,
                textAlign: "justify",
              }}
            >
              FarmTrek is a system that uniquely combines the livestock and
              financial services ecosystems to enhance the livestock value chain
              and bring new financial services to smallholder livestock farmers.
              Smallholder livestock farmers, which represent a large population
              of the livestock industry in most emerging markets, are unable to
              collateralise their ‘livestock as an asset’ to access formal
              financing, and livestock insurance is not widely accessible to
              these farmers, mainly due to moral hazard and fraud issues.
              FarmTrek encourages financial inclusion for smallholder farmers by
              improving liquidity within the livestock value chain. Smallholder
              livestock farmers would access working capital loans using their
              livestock as collateral, livestock insurance services and cashless
              payments. Our solution addresses issues of:
              <ul style={{ textAlign: "left" }}>
                <li>IDENTITY FRAUD</li>
                <li>ASSET OWNERSHIP FRAUD</li>
                <li>DATA SECURITY RISK</li>
                <li>CREDIT ASSESSMENT RISK</li>
                <li>LIVESTOCK INSURANCE MORAL HAZARD</li>
                <li>LIVESTOCK TRACKING AND TRACEABILITY</li>
                <li>COST-EFFECTIVENESS</li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FarmTrekSection;
