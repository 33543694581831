import React from "react";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ContactSubSection = () => {
  const theme = useTheme();
  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box
        item
        sx={{
          pt: "20px",
          pb: "20px",
          typography: "h3",
          width: "100%",
          color: theme.palette.primary.light,
          textAlign: "center",
        }}
      >
        INFOCORP TECHNOLOGIES
      </Box>

      <Box
        item
        sx={{
          typography: "body",
          color: theme.palette.primary.light,
          width: "100%",
          textAlign: "center",
          pb: "20px",
        }}
      >
        7 Temasek Boulevard #12-07, Suntec Tower One, Singapore 038987
      </Box>
    </Box>
  );
};

export default ContactSubSection;
