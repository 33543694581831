import { Button } from "@mui/material";
import { styled } from "@mui/system";

const Logo = styled((props) => (
  <Button
    {...props}
    onClick={() => {
      window.location.href = "/";
    }}
  />
))((props) => ({
  backgroundImage: props.theme.palette.logo.backgroundImage,
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  height: "40px",
  width: "120px",
}));

export default Logo;
