import React from "react";
import { Grid, Container, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const AboutUsText = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          typography: "h2",
          textAlign: "center",
          color: theme.palette.primary.dark,
          width: "100%",
          mt: "20px",
          mb: "20px",
        }}
      >
        ABOUT US
      </Box>
      <Box
        sx={{
          typography: "body",
          textAlign: "justify",
          width: "100%",
          color: theme.palette.primary.dark,
          pl: "20px",
          pr: "20px",
          pb: "20px",
        }}
      >
        Founded in 2015 with headquarters in Singapore, InfoCorp Technologies’
        aim as an integrated FinTech and AgriTech company is to bring inclusive
        financial services to the livestock industry in emerging markets via its
        blockchain-based platform, FarmTrek. FarmTrek was successfully piloted
        with two Myanmar government agencies, and launched in 2019. Following
        this success, InfoCorp has partnered with the Rwanda government to
        launch a project, joined the Smart Africa Alliance as a Private Sector
        member, and showcased FarmTrek at the Afro-Asia Fintech Festival
        organised by the Monetary Authority of Singapore (MAS) and Central Bank
        of Kenya. The company’s innovation has been acknowledged as one of 10
        winning business models for women entrepreneurs, co-funded by the United
        Nations to improve access to finance for Women micro, small and medium
        enterprises (MSMEs) via the UN FinTech Innovation Fund. Bringing
        together best talent across the globe, InfoCorp has staff in Rwanda,
        Myanmar, India, Vietnam, Argentina, and the US.
      </Box>
    </>
  );
};

const Tagline = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "block",
        p: 5,
        typography: "tagline",
        [theme.breakpoints.up("lg")]: {
          pr: 20,
          pl: 20,
          pb: 10,
        },
      }}
    >
      <Typography variant="taglineHighlight">InfoCorp</Typography> is a
      blockchain technology company that combines{" "}
      <Typography variant="taglineHighlight">FinTech</Typography> and{" "}
      <Typography variant="taglineHighlight">AgriTech</Typography> in solving
      the financial inclusion problem.
    </Box>
  );
};

const FeatureBox = ({ src, title, ...props }) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "center",
          p: 5,
        }}
      >
        <img src={src} width="100px" />
      </Box>
      <Box sx={{ typography: "h3", textAlign: "center", width: "100%" }}>
        {title}
      </Box>
      <Box
        sx={{
          typography: "body",
          mt: "20px",
          pl: "20px",
          pr: "20px",
          pb: "20px",
          textAlign: "center",
          [theme.breakpoints.up("md")]: {
            textAlign: "justify",
          },
        }}
      >
        {props.children}
      </Box>
    </>
  );
};

const Vision = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        backgroundColor: theme.palette.primary.dark,
        height: "150px",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          alignContent: "center",
          justifyContent: "right",
          p: "10px",
          mt: "auto",
          mb: "auto",
        }}
      >
        <img src="./img/vision.svg" width="80px" />
      </Box>
      <Box sx={{ mt: "auto", mb: "auto", p: "10px", width: "320px" }}>
        <Box
          sx={{
            typography: "h2",
            p: "10px",
            color: theme.palette.primary.light,
          }}
        >
          OUR VISION
        </Box>
        <Box
          sx={{
            typography: "body",
            p: "10px",
            color: theme.palette.primary.light,
          }}
        >
          Creating value, improving lives.
        </Box>
      </Box>
    </Box>
  );
};

const Mission = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        backgroundColor: theme.palette.primary.light,
        height: "150px",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          alignContent: "center",
          justifyContent: "right",
          p: "10px",
          mt: "auto",
          mb: "auto",
        }}
      >
        <img src="./img/mission.svg" width="80px" />
      </Box>
      <Box
        sx={{
          width: "320px",
          mt: "auto",
          mb: "auto",
          p: "10px",
        }}
      >
        <Box
          sx={{
            typography: "h2",
            p: "10px",
            color: theme.palette.primary.dark,
          }}
        >
          OUR MISSION
        </Box>
        <Box
          sx={{
            typography: "body",
            p: "10px",
            color: theme.palette.primary.dark,
          }}
        >
          To create an inclusive world with equal opportunities for all to
          succeed in life.
        </Box>
      </Box>
    </Box>
  );
};

const AboutUsSection = ({ ...props }) => {
  const theme = useTheme();
  return (
    <div {...props}>
      <Container>
        <AboutUsText />
        <Tagline />
        <Grid container>
          <Grid item xs={12} md={4}>
            <FeatureBox title="SECURED AND ENSURED" src="/img/secure.svg">
              Face-to-face and remote electronic payment transactions are
              secured and ensured.
            </FeatureBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <FeatureBox title="REDUCED COST" src="/img/cost.svg">
              Reduces costs by eliminating the traditionally followed escrow
              system.
            </FeatureBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <FeatureBox title="AVOID FRAUD" src="/img/fraud.svg">
              Exposure to cash fraud is reduced, so you have improved business
              certainty and peace of mind
            </FeatureBox>
          </Grid>
        </Grid>
      </Container>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Vision />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Mission />
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutUsSection;
